<template>
  <div class="spreadWrap">
    <div @click="logout" class="logout">
      <p>
        <span>XXXX学校</span>
        <span>体验截止日期：2023年8月30日</span>
      </p>
      <span>退出</span>
    </div>
    <div class="wrap">
      <dl class="left">
        <dt>视功能测试</dt>
        <dd>
          <div
            v-for="(item, index) in test"
            :key="index"
            @click="goGame(item)"
            @mouseover="mouseover(index)"
            @mouseout="mouseout(index)"
          >
            <span>
              <img
                :src="require('@/assets/images/' + item.imgUrl)"
                alt="icon"
              />
            </span>
            {{ item.name }}
          </div>
        </dd>
      </dl>
      <dl class="right">
        <dt>视功能训练</dt>
        <dd>
          <div
            v-for="(item, index) in train"
            :key="index"
            @click="goPage(item.url)"
          >
            {{ item.type }}
            <!-- {{ item.name }}<span>（{{ item.type }}）</span> -->
          </div>
        </dd>
      </dl>
    </div>
    <div class="tips">
      <div>
        <exclamation-circle-outlined />
        <dl>
          <dt>声明：</dt>
          <dd>
            ① 【视功能测试】和【视功能训练】内容为体验内容，结果仅供定性参考<br />
            ② 体验中须佩戴亿目佳<sup>®️</sup>专用训练眼镜<br />
            ③ 如需保护视力、防控近视，请购买【亿目佳<sup>®️</sup>护眼魔盒】
            （购买路径：扫描右侧二维码关注公众号，点击【护眼磨盒】→【护眼商城】）

            <!-- ①“测一测”（视觉检查），结果仅供定性参考，不作为临床诊断依据。<br />
            ②“比一比”（视觉训练）的内容仅供体验。<br />
            ③ 如需保护视力、防控近视，推荐购买亿目佳<sup>®️</sup>护眼魔盒。<br />
            ④ 体验中须佩戴亿目佳<sup>®️</sup>专用红蓝眼镜。 -->
          </dd>
        </dl>
      </div>
      <img src="@/assets/images/code.png" class="code" alt="公众号" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "spread-index",
  components: {
    ExclamationCircleOutlined,
  },
  props: {},
  emits: [],
  setup() {
    const router = useRouter();
    const test = ref([
      {
        name: "抑制度检查",
        url: "fourLight",
        imgUrl: "iconFocus02.png",
        index: 0,
      },
      {
        name: "动态知觉眼位检查",
        url: "eyePosition",
        imgUrl: "iconFocus01.png",
        index: 1,
      },
    ]);

    const train = [
      { name: "农场保卫战", url: "an-beat-birds", type: "脱抑制训练" },
      { name: "小羊回家", url: "pickSheep", type: "同时视训练" },
      { name: "立体连连看", url: "ste917", type: "立体视训练" },
      { name: "小猫散开", url: "fu202", type: "融合训练" },
    ];

    const urlArr = [
      { urlHover: "icon02.png", url: "iconFocus02.png" },
      { urlHover: "icon01.png", url: "iconFocus01.png" },
    ];

    const mouseover = (i) => {
      test.value[i].imgUrl = urlArr[i].urlHover;
    };
    const mouseout = (i) => {
      test.value[i].imgUrl = urlArr[i].url;
    };

    const goGame = (item) => {
      router.push({
        path: "/spreadGame",
        query: {
          index: item.index,
          url: item.url,
        },
      });
    };

    const goPage = (url) => {
      router.push({
        path: "/spreadTrain",
        query: {
          url: url,
        },
      });
    };

    const logout = () => {
      router.push("/login");
    };
    return {
      goPage,
      test,
      goGame,
      train,
      logout,
      mouseover,
      mouseout,
    };
  },
});
</script>
<style lang="less" scoped>
.tips {
  img {
    width: 130px;
  }
  dd {
    font-size: 22px;
    line-height: 30px;
    color: rgba(26, 26, 26, 1);
  }
  dt {
    color: rgba(26, 26, 26, 1);
    font-size: 24px;
  }
  .anticon-exclamation-circle {
    font-size: 29px;
    color: rgba(14, 104, 76, 1);
    margin-right: 16px;
    margin-top: 4px;
  }
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 15px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}
.wrap {
  > dl {
    > dd {
      > div {
        > span {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          font-weight: 200;
        }
        background-color: rgba(255, 255, 255, 0.7);
        margin-bottom: 76px;
        width: 320px;
        height: 120px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        line-height: 24px;
        font-size: 30px;
        color: rgba(26, 26, 26, 1);
        padding: 28px 0;
        box-sizing: border-box;
        transition: all 0.5s linear;
        cursor: pointer;
      }
      > div:hover {
        background-color: rgba(14, 104, 76, 1);
        color: #fff;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 50px 0;
    }
    > dt {
      width: 260px;
      height: 80px;
      border-radius: 5px;
      background-color: #fff;
      color: rgba(51, 51, 51, 1);
      font-size: 44px;
      font-weight: bold;
      text-align: center;
      line-height: 80px;
      margin: 0 auto 78px;
      // margin-bottom: 78px;
    }
    flex: 1;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;
  }
  .left {
    > dd {
      > div {
        width: 370px;
        padding: 12px 0;
      }
      justify-content: center;
    }
    background-color: rgba(255, 255, 255, 0.4);
    border-right: 2px dashed #ccc;
  }
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout {
  > P {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: rgba(14, 104, 76, 1);
    > span {
      letter-spacing: 3px;
      display: block;
      height: 35px;
    }
  }
  > span {
    width: 160px;
    height: 60px;
    background-color: rgba(200, 200, 200, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: rgba(14, 104, 76, 1);
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s linear;
  }
  > span:hover {
    background-color: rgba(14, 104, 76, 1);
    color: #fff;
  }
  display: flex;
  justify-content: space-between;
  padding-bottom: 45px;
}
.spreadWrap {
  background: url("~@/assets/images/bg.png") center/cover;
  min-height: 100vh;
  width: 100%;
  padding: 44px 180px;
  box-sizing: border-box;
}
</style>